import { render, staticRenderFns } from "./cardServiceAddDialog.vue?vue&type=template&id=6623123f&scoped=true&"
import script from "./cardServiceAddDialog.vue?vue&type=script&lang=js&"
export * from "./cardServiceAddDialog.vue?vue&type=script&lang=js&"
import style0 from "./cardServiceAddDialog.vue?vue&type=style&index=0&id=6623123f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6623123f",
  null
  
)

export default component.exports